import { DARK_SPOT_FORMULA_CODE, SIGNUP_CODE } from 'consts/landingVariants';
import type { LandingVariantCodesType } from 'consts/landingVariants';

export type VanityPageType = 'Signup' | 'Dark Spot Formula';

type ContentfulVanityVariants = {
  [key in VanityPageType]: LandingVariantCodesType;
};

export const CONTENTFUL_VANITY_VARIANTS: ContentfulVanityVariants = {
  Signup: SIGNUP_CODE,
  'Dark Spot Formula': DARK_SPOT_FORMULA_CODE,
} as const;
