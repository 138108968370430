export const HOME = 'home';
export const SIGNUP = 'signup';
export const SIGNUP_MARKETING_SAFE = 'signup-marketing-safe';
export const DARK_SPOT_FORMULA = 'dark-spot-formula';
export const SHAPE_BEAUTY_BOX = 'shape-beauty-box';

export const SIGNUP_CODE = '1n';
export const SIGNUP_MARKETING_SAFE_CODE = '1g';
export const DARK_SPOT_FORMULA_CODE = '2n';
export const SHAPE_BEAUTY_BOX_CODE = 'sbb';

export const MARKETING_SAFE_VARIANTS = [SIGNUP_MARKETING_SAFE];

export const isHomepageVariant = (variant: string) => variant === HOME;

export const isMarketingSafeVariant = (variant: string) =>
  !!MARKETING_SAFE_VARIANTS.find(
    (marketingSafeVariant) => marketingSafeVariant === variant,
  );

export type LandingVariantsType =
  | typeof HOME
  | typeof SIGNUP
  | typeof SIGNUP_MARKETING_SAFE
  | typeof DARK_SPOT_FORMULA
  | typeof SHAPE_BEAUTY_BOX;

// DO NOT ADD DISPOSABLE MARKETING CODES.
// Variant codes here are saved to the database and associated
// to a user permanently.
export type LandingVariantCodesType =
  | typeof SIGNUP_CODE
  | typeof SIGNUP_MARKETING_SAFE_CODE
  | typeof DARK_SPOT_FORMULA_CODE
  | typeof SHAPE_BEAUTY_BOX_CODE;
