import { useEffect } from 'react';
import { graphql, navigate } from 'gatsby';

import { captureReferrerUrl } from 'utils/captureReferrer';
import { CONTENTFUL_VANITY_VARIANTS } from 'consts/contentfulVanityVariants';
import type { VanityPageType } from 'consts/contentfulVanityVariants';

interface VanityRedirectProps {
  data: {
    contentfulPageVanityUrl: {
      token: string;
      landingPage: VanityPageType;
      utmCampaign: string;
      utmCreative: string;
      utmMedium: string;
      utmSource: string;
      bannerText: string;
    };
  };
}

const VanityRedirect = ({ data }: VanityRedirectProps) => {
  const {
    contentfulPageVanityUrl: {
      token,
      landingPage,
      utmCampaign,
      utmCreative,
      utmMedium,
      utmSource,
      bannerText,
    },
  } = data;

  useEffect(() => {
    captureReferrerUrl();

    const url = new URL(window.location.href);
    const queryParams = new URLSearchParams(url.search);
    queryParams.set('utm_source', utmSource);
    queryParams.set('utm_medium', utmMedium);
    queryParams.set('utm_campaign', utmCampaign);
    queryParams.set('utm_creative', utmCreative);
    queryParams.set('t', token);
    const variant = CONTENTFUL_VANITY_VARIANTS[landingPage];
    navigate(`/free-trial/${variant}?${queryParams.toString()}`, {
      replace: true,
      state: { bannerText },
    });
  }, []);

  return null;
};

export const query = graphql`
  query VanityRedirectQuery($id: String!) {
    contentfulPageVanityUrl(id: { eq: $id }) {
      token
      landingPage
      utmCampaign
      utmCreative
      utmMedium
      utmSource
      bannerText
    }
  }
`;

export default VanityRedirect;
